import { AppDispatch } from '@/store'
import { userLogout } from '@/store/slices/authSlice'
import theme from '@/theme'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'

import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'

const useStyle = makeStyles({
    container: {
        width: 150,
        backgroundColor: theme.colors.white,
    },
    listItemRoot: {
        paddingBottom: 0,
        paddingTop: 0,
    },
})

export default function AccountOptions() {
    const classes = useStyle()
    const dispatch = useDispatch<AppDispatch>()

    const handleSignOut = () => {
        dispatch(userLogout())
    }

    const options = useMemo(() => {
        const options = [{ text: 'ログアウト', action: handleSignOut }]
        return options
    }, [])

    return (
        <Box boxShadow={3} className={classes.container}>
            <List component="nav" aria-label="main mailbox folders">
                {options.map((option, i) => {
                    return (
                        <ListItem key={i} button classes={{ root: classes.listItemRoot }}>
                            <ListItemText primary={option.text} onClick={option.action} />
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}
