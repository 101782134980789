import React from 'react'

export default function BusinessIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.204" height="22" viewBox="0 0 22.204 22">
            <g id="icon_data" transform="translate(-1 -5.996)">
                <path
                    id="Path_3556"
                    data-name="Path 3556"
                    d="M2,31.869V17H6.1"
                    transform="translate(0 -4.873)"
                    fill="none"
                    stroke="#42526e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_3557"
                    data-name="Path 3557"
                    d="M18,27V7H30V27"
                    transform="translate(-7.796 -0.004)"
                    fill="none"
                    stroke="#42526e"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_407"
                    data-name="Ellipse 407"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(4.508 14.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_408"
                    data-name="Ellipse 408"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(13.108 14.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_409"
                    data-name="Ellipse 409"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(17.008 14.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_410"
                    data-name="Ellipse 410"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(17.008 10.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_411"
                    data-name="Ellipse 411"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(4.508 18.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_412"
                    data-name="Ellipse 412"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(13.108 18.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_413"
                    data-name="Ellipse 413"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(17.008 18.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_414"
                    data-name="Ellipse 414"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(4.508 22.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_415"
                    data-name="Ellipse 415"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(13.108 22.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_416"
                    data-name="Ellipse 416"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(17.008 22.997)"
                    fill="#42526e"
                />
                <circle
                    id="Ellipse_417"
                    data-name="Ellipse 417"
                    cx="1.25"
                    cy="1.25"
                    r="1.25"
                    transform="translate(13.108 10.997)"
                    fill="#42526e"
                />
            </g>
        </svg>
    )
}
