import ExpandedIcon from '@/components/atoms/Icon/ExpandedIcon'
import { COMPANY_LINKS } from '@/constant/companyLinks'
import { routes } from '@/routes'
import theme from '@/theme'
import { handleScrollToTop } from '@/utils/scrollHelper'
import { Grid } from '@mui/material'
import { Link } from 'gatsby'
import React, { CSSProperties, useEffect, useState } from 'react'

const styles: { [key: string]: CSSProperties } = {
    root: {
        position: 'relative',
        marginTop: 50,
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        padding: '30px 0',
        borderTop: `1px solid ${theme.colors.primaryN}`,
        backgroundColor: theme.colors.white,
    },
    link: {
        color: theme.colors.primaryN,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 300,
    },
    copyright: {
        marginTop: 20,
        textAlign: 'center',
    },
    aboutUs: {
        textAlign: 'right',
        marginRight: 100,
    },
    expandWrapper: {
        position: 'relative',
    },
    expandBtn: {
        height: 21,
        position: 'absolute',
        top: -29,
        right: 50,
        padding: '3px 10px',
        border: `1px solid ${theme.colors.primaryN}`,
        borderBottom: `1px solid ${theme.colors.white}`,
        background: theme.colors.white,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    expandBtnShrink: {
        height: 28,
        top: -28,
    },
    textRight: {
        textAlign: 'right',
    },
    textLeft: {
        textAlign: 'left',
    },
    rootShrink: {
        padding: '30px 20px',
    },
}
export const Footer = ({ isShrink }: { isShrink?: boolean }) => {
    const [showFooter, setShowFooter] = useState(false)
    const [show, setShow] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setShowFooter(true)
        }, 100)
    }, [])

    if (!showFooter) return null
    return (
        <div style={isShrink ? { ...styles.root, ...styles.rootShrink } : { ...styles.root }}>
            {show && (
                <div
                    style={isShrink ? { ...styles.expandBtn, ...styles.expandBtnShrink } : { ...styles.expandBtn }}
                    onClick={() => handleScrollToTop()}
                >
                    <ExpandedIcon direction="up" />
                </div>
            )}
            <Grid container>
                <Grid item xs={6}>
                    <div
                        style={
                            isShrink
                                ? { ...styles.link, ...styles.aboutUs, ...styles.textLeft }
                                : { ...styles.link, ...styles.aboutUs }
                        }
                    >
                        {isShrink ? (
                            <Link style={styles.link} to={routes.PRIVACY_POLICY}>
                                プライバシーポリシー
                            </Link>
                        ) : (
                            <a
                                style={styles.link}
                                href={isShrink ? routes.PRIVACY_POLICY : routes.PRIVACY_POLICY_FULL}
                                target="_blank"
                            >
                                プライバシーポリシー
                            </a>
                        )}
                        　｜　
                        <a style={styles.link} href={COMPANY_LINKS.TBM} target="_blank">
                            運営会社について
                        </a>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {isShrink ? (
                        <div style={{ ...styles.link, ...styles.textRight }}>
                            Copyright © TBM Co.,Ltd. All Rights Reserved.
                        </div>
                    ) : (
                        <div className="font-default">
                            <a style={styles.link} href={COMPANY_LINKS.LIMEX} target="_blank">
                                LIMEX
                            </a>
                            　｜　
                            <a style={styles.link} href={COMPANY_LINKS.CIRCILE_X} target="_blank">
                                CirculeX
                            </a>
                            　｜　
                            <a style={styles.link} href={COMPANY_LINKS.MAAR} target="_blank">
                                MaaR
                            </a>
                            　｜　
                            <a style={styles.link} href={COMPANY_LINKS.ZAIMA} target="_blank">
                                ZAIMA
                            </a>
                            　｜　
                            <a style={styles.link} href={COMPANY_LINKS.CAREERS} target="_blank">
                                CAREERS
                            </a>
                        </div>
                    )}
                </Grid>
            </Grid>
            {!isShrink && (
                <div style={{ ...styles.copyright, ...styles.link }}>Copyright © TBM Co.,Ltd. All Rights Reserved.</div>
            )}
        </div>
    )
}

export const withFooter = (Content: () => JSX.Element) => {
    return () => (
        <div>
            {<Content />}
            {<Footer />}
        </div>
    )
}
