import { Footer } from '@/components/molecules/Footer'
import Header from '@/components/molecules/Header/index'
import Helmet from '@/components/molecules/Helmet'
import MenuBar from '@/components/molecules/Menu'
import { routes } from '@/routes'
import { AppDispatch } from '@/store'
import { authStore, checkAuthenticator } from '@/store/slices/authSlice'
import { setExpandedMenu, systemStore } from '@/store/slices/systemSlice'
import { default as customTheme, default as theme } from '@/theme'
import { CssBaseline, Theme, ThemeProvider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { navigate } from 'gatsby'
import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export type TExpandedMenu = 'true' | 'false' | null
interface TLayout {
    children: ReactNode | ReactNode[]
}

const drawerWidth = 256

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflow: 'auto',
        },
        flexCenter: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        root: {
            display: 'flex',
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        contentWrapper: {
            paddingTop: 94,
            flexGrow: 1,
            padding: 24,
            marginLeft: 72,
            transition: 'margin-left 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            backgroundColor: customTheme.colors.white,
            minWidth: `calc(1280px - ${drawerWidth}px - 25px)`,
        },
        content: {
            minHeight: 'calc(100vh - 240px)',
        },
        contentExpanded: {
            marginLeft: drawerWidth,
        },
    }),
)

function Layout({ children }: TLayout) {
    const path = typeof window !== 'undefined' ? window.location.pathname : ''
    const dispatch = useDispatch<AppDispatch>()
    const { logedIn } = useSelector(authStore)
    const { expandedMenu: open } = useSelector(systemStore)

    const checkTabExpanded = () => {
        const idsArr: number[] = []
        if (path === routes.home) idsArr.push(1)
        if (path.includes(routes.emissionFactor)) idsArr.push(2)
        if (path.includes(routes.notification)) idsArr.push(3)
        return idsArr
    }
    const initialTabId = checkTabExpanded()
    const [expandedMenuId, setExpandedMenuId] = useState<number[]>(initialTabId)
    const classes = useStyles()

    useEffect(() => {
        dispatch(checkAuthenticator())
    }, [])

    const handleDrawerOpen = () => {
        dispatch(setExpandedMenu({ expand: true }))
        setExpandedMenuId(initialTabId)
    }
    const handleDrawerClose = () => {
        dispatch(setExpandedMenu({ expand: false }))
        setExpandedMenuId([])
    }
    const handleTabClick = (p?: string, id?: number) => {
        if (p) return navigate(p)
        if (id && !expandedMenuId.includes(id)) {
            if (!open) handleDrawerOpen()
            setExpandedMenuId((pre) => [...pre, id])
            return
        }
        if (id && expandedMenuId.includes(id))
            return setExpandedMenuId((pre) => {
                const newIds = pre.filter((preId) => preId !== id)
                return newIds
            })
    }

    return logedIn ? (
        <div className={classes.container}>
            <Helmet />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header open={open} drawerWidth={drawerWidth} />
                <MenuBar
                    open={open}
                    expandedMenuId={expandedMenuId}
                    initialTabId={initialTabId}
                    drawerWidth={drawerWidth}
                    handleDrawerClose={handleDrawerClose}
                    handleDrawerOpen={handleDrawerOpen}
                    handleTabClick={handleTabClick}
                />
                <main id="main" className={`${classes.contentWrapper} ${open ? classes.contentExpanded : ''}`}>
                    <div className={classes.content}>{children}</div>
                    <Footer isShrink />
                </main>
            </ThemeProvider>
        </div>
    ) : null
}

export function withLayout(Content: () => JSX.Element) {
    return function () {
        return <Layout>{<Content />}</Layout>
    }
}
