import theme from '@/theme'
import { Tooltip, TooltipProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { CSSProperties } from 'react'

export const CustomTooltip = ({
    placement = 'top',
    width,
    height,
    styleTooltip,
    ...props
}: TooltipProps & {
    width?: string | number
    height?: string | number
    styleTooltip?: CSSProperties
}) => {
    const useStyle = makeStyles({
        tooltip: {
            backgroundColor: theme.colors.primaryN,
            fontSize: 14,
            fontWeight: 300,
            minWidth: width,
            padding: 12,
            ...styleTooltip,
        },
        tooltipArrow: {
            color: theme.colors.primaryN,
        },
    })
    const classes = useStyle()
    return (
        <Tooltip
            {...props}
            arrow
            placement={placement}
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        >
            {props.children}
        </Tooltip>
    )
}
