import React from 'react'

export default function DashboardIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path
                id="columns-gap"
                d="M6.75,1.125V4.5H1.125V1.125ZM1.125,0A1.125,1.125,0,0,0,0,1.125V4.5A1.125,1.125,0,0,0,1.125,5.625H6.75A1.125,1.125,0,0,0,7.875,4.5V1.125A1.125,1.125,0,0,0,6.75,0Zm15.75,13.5v3.375H11.25V13.5ZM11.25,12.375A1.125,1.125,0,0,0,10.125,13.5v3.375A1.125,1.125,0,0,0,11.25,18h5.625A1.125,1.125,0,0,0,18,16.875V13.5a1.125,1.125,0,0,0-1.125-1.125ZM6.75,9v7.875H1.125V9ZM1.125,7.875A1.125,1.125,0,0,0,0,9v7.875A1.125,1.125,0,0,0,1.125,18H6.75a1.125,1.125,0,0,0,1.125-1.125V9A1.125,1.125,0,0,0,6.75,7.875Zm15.75-6.75V9H11.25V1.125ZM11.25,0a1.125,1.125,0,0,0-1.125,1.125V9a1.125,1.125,0,0,0,1.125,1.125h5.625A1.125,1.125,0,0,0,18,9V1.125A1.125,1.125,0,0,0,16.875,0Z"
                fill="#42526e"
            />
        </svg>
    )
}
