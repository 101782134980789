import React from 'react'

export default function NotificationsIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.374" height="21" viewBox="0 0 18.374 21">
            <path
                id="bell-fill"
                d="M10.187,21a2.625,2.625,0,0,0,2.625-2.625H7.562A2.625,2.625,0,0,0,10.187,21ZM11.493,1.443a1.312,1.312,0,1,0-2.612,0A6.565,6.565,0,0,0,3.625,7.875c0,1.441-.656,7.875-2.625,9.187H19.374c-1.969-1.312-2.625-7.746-2.625-9.187A6.564,6.564,0,0,0,11.493,1.443Z"
                transform="translate(-1 0.001)"
                fill="#42526e"
            />
        </svg>
    )
}
