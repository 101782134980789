import React from 'react'

export default function ArrowExpandIcon({ direction = 'left' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
            style={{
                rotate: direction === 'right' ? '180deg' : '0deg',
                transition: 'rotate 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
        >
            <path
                d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                fill="#42526e"
            />
        </svg>
    )
}
